import React from "react" 
import Layout from "../components/layout"
import SEO from "../components/seo" 
import ContactForm from '../components/contact'

const ContactPage = () => (
  <Layout>
    <SEO title="CORSAF contactez nous" /> 
     <div class="grid cols-1 md:grid-cols-2 md:mx-40 py-10 gap-10">
       <div>  
         <h1 class="mb-3">Contact</h1>
         <div class="border border-red-800 border-4 w-12"></div>
         <p class="my-5">
         Afin de répondre au mieux à vos attentes, nous vous remercions de bien vouloir remplir le formulaire ci-dessous.
         </p>
         <form class="w-full max-w-lg">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3 mb-6 md:mb-0">
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Nom" /> 
            </div>
          </div> 
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" placeholder="e-mail" /> 
            </div>
          </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Sujet" />
            </div>
           </div>
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <textarea class=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" placeholder="Message" id="message"></textarea> 
            </div>
          </div>
          <div class="md:flex md:items-center">
            <div class="md:w-1/3">
              <button class="shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-gray font-bold py-2 px-4 rounded" type="button">
                Envoyer
              </button>
            </div>
            <div class="md:w-2/3"></div>
          </div>
        </form>
       </div>
       <div> 
         <h3>Notre bureau</h3>
         <div class="border border-red-800 border-4 w-12"></div>
            <ul class="text-sm my-6 ml-0">
              <li class="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width="22" height="22" class="inline mr-2">
                  <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  <p class="gray">+212 (0)528 247 711</p>
              </li>
              <li class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" class="inline mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg> <p class="gray">ste.corsaf@gmail.com</p>
              </li>
              <li class="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="inline mr-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd" />
                  </svg>
                  <p class="gray text-xs"> Imm GDIRA 3ème étage N°8 BV Mohamed 6, Ait Melloul - Agadir</p>
              </li>
           </ul>
           <h3 class="mt-10">Heures de travail</h3>
         <div class="border border-red-800 border-4 w-12"></div>
         <ul class="mt-6 ml-0 text-sm">
            <li>&#x1F558; Du lundi au vendredi de 9h à 17h</li>
            <li>&#x1F558; Samedi de 9h à 14h</li>
            <li> &#x1F558; Dimanche - Fermé</li>
         </ul>
       </div>
     </div>
     <div class="w-full">
     <div class="mt-3"> <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13772.531423582876!2d-9.511576204846195!3d30.347173923148606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb27dfa4495318b58!2sCorsaf%20Design!5e0!3m2!1sen!2sma!4v1612386710632!5m2!1sen!2sma" width="100%" height="400px" frameborder="0"   allowfullscreen="" aria-hidden="false" tabindex="0"></iframe></div> 
       
     </div>
    </Layout>
)

export default ContactPage